// FullscreenModal.js
import React from 'react';
import '../styles/fullscreenModal.css';

function FullscreenModal({ isOpen, onClose, imageSrc }) {
	if (!isOpen) return null;

	return (
		<div className='fullscreenModal'>
			<button className='closeButton' onClick={onClose}>
				X
			</button>
			<img src={imageSrc} alt='Fullscreen' className='fullscreenImage' />
		</div>
	);
}

export default FullscreenModal;
