import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal';
import Image4 from '../assets/images/talha.jpg';
import {
	FaDesktop,
	FaLanguage,
	FaCoffee,
	FaMobileAlt,
	FaBirthdayCake,
} from 'react-icons/fa';
import TurkishFlag from '../assets/images/images.png';

function WhoAmI({ onClose }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openLinkedInIhsan = () => {
		window.open('https://www.linkedin.com/in/ihsanktmr/', '_blank');
	};

	const openLinkedInBurak = () => {
		window.open(
			'https://www.linkedin.com/in/mustafa-burak-yilmaz-7b0424183/',
			'_blank'
		);
	};

	const openLinkedInUgurcan = () => {
		window.open('https://www.linkedin.com/in/katmerugurcan/', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='Talha Önder'
								className='profileImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>Talha Önder</h2>
								<span className='projectCategory'>
									React & React Native Developer
								</span>
							</div>
						</div>
					</div>

					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<img
									src={TurkishFlag}
									alt='Turkish Flag'
									className='flag-icon'
								/>
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Türkiye</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>Turkish, English</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaBirthdayCake />
							</div>
							<div className='info-text'>
								<h4>Age</h4>
								<p>21</p>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About Talha</h2>
						<div>
							<p className='projectDescription'>
								Hello! I'm Talha Önder, who specializes in
								developing applications using React &
								React-Native. I Try to develop attractive
								interactive cross-platform applications. In such
								projects as GlutenMetre or UlkePuan, I acted as
								a team leader and worked with the European
								Union, iED on large scale environmental
								projects. During all these activities, I have
								learned API integration, responsive web design,
								mobile application development focusing on high
								impact user centered design. Being engaged in
								meaningful work and tackling new challenges that
								would help me to progress is appealing to me. I
								have a strong interest in developing
								applications where I am able to use my technical
								knowledge and innovative thinking to the maximum
								and create top-notch applications with a focus
								on user experience. I enjoy using technology to
								address real-life issues and work in a team
								environment where I can enhance my skills.
							</p>

							<h2 className='headerText'>🛠️ My Gains</h2>
							<ul className='projectDescription'>
								<li>
									<strong>
										Technical Knowledge and Experience:
									</strong>
									{''} Knowing React and React Native has
									improved my application development
									abilities-enhancing my work on personal and
									workplace projects as well. I have rather
									completed my skills through practical
									involvement in projects such as GlutenMetre
									and UlkePuan.
								</li>
								<li>
									<strong>Teamwork and Communication:</strong>
									{''} I have learnt to communicate better
									since I have worked with teammates who come
									from different cultures. I learned the
									positive aspects of communicating with
									colleagues and how this communication can
									affect the processes involved in a project.
								</li>
								<li>
									<strong>Problem Solving Skills:</strong>
									{''} I have enhanced my skills of finding
									new ways to solve problems that I
									encountered. I also improved my skills in
									identifying client needs and implementing
									measures that would meet such needs.
								</li>
								<li>
									<strong>
										Continuous Learning Motivation:
									</strong>
									{''} I have a good desire to learn
									particularly due to the trainings and
									projects that I engage in mobile application
									development. I am always a step forward in
									searching new technologies and seeking
									self-enhancement.
								</li>
							</ul>
							<h2 className='headerText'>📚 Hobbies</h2>
							<ul className='projectDescription'>
								<li>
									Crazy about books, once I am done with my
									work, I read as a hobby during my spare
									time.
								</li>
								<li>
									Love spending time outdoors going for walks
									or going for a whale to relax and
									rejuvenate.
								</li>
								<li>
									Have strong liking for playing games
									especially the ones which require a lot of
									thinking and strategizing.
								</li>
								<li>
									For me, driving does wonders in relieving
									stress and allows me to enjoy the ride and
									the view.
								</li>
							</ul>

							<h2 className='headerText'>📈 My Future Goals</h2>
							<ul className='projectDescription'>
								<li>
									<strong>
										Advanced Technology Competence:
									</strong>
									{''} By increasing my competence in React
									and React Native, I aim to have deeper
									knowledge, especially in mobile application
									development. I want to produce more
									efficient solutions by constantly updating
									myself with new technologies and tools.
								</li>
								<li>
									<strong>
										Project Management and Leadership:
									</strong>
									{''} I aim to improve my project management
									skills and assume a leadership role within a
									team. I want to contribute to the successful
									completion of projects by improving my
									teamwork and leadership skills.
								</li>
								<li>
									<strong>
										User Experience Oriented Design:
									</strong>
									{''} I plan to become more involved in
									design processes and proactively evaluate
									user feedback to maximize user experience.
									This will enable me to develop user-friendly
									applications.
								</li>
								<li>
									<strong>Open Source Contribution:</strong>
									{''} By contributing to open source
									projects, I aim to benefit both my personal
									development and the community. In this
									process, I want to gain new skills by
									collaborating with different teams.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default WhoAmI;
