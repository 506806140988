import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal';
import Image4 from '../assets/images/deka.jpeg';
import { FaDesktop, FaLanguage, FaCoffee, FaMobileAlt } from 'react-icons/fa';
import TurkishFlag from '../assets/images/images.png';

function Deka({ onClose }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openLinkedInIhsan = () => {
		window.open('https://www.linkedin.com/in/ihsanktmr/', '_blank');
	};

	const openLinkedInBurak = () => {
		window.open(
			'https://www.linkedin.com/in/mustafa-burak-yilmaz-7b0424183/',
			'_blank'
		);
	};

	const openLinkedInUgurcan = () => {
		window.open('https://www.linkedin.com/in/katmerugurcan/', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='Deka Technology'
								className='logoImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>
									Deka Technology
								</h2>
								<span className='projectCategory'>
									IT Services and Consulting
								</span>
							</div>
						</div>
					</div>

					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<img
									src={TurkishFlag}
									alt='Turkish Flag'
									className='flag-icon'
								/>
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Türkiye</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>Turkish</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaCoffee />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Internship</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaMobileAlt />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>iOS & Android</p>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this Job</h2>
						<span className='projectCategory'>
							September 2023 - January 2024
						</span>
						<div>
							<p className='projectDescription'>
								🚀 Junior React Native Developer Candidate 🚀
							</p>
							<p className='projectDescription'>
								Hello! I'm Talha Önder, and I have completed a
								90-day intensive learning process to become a
								React Native developer. During this period, I
								not only developed my core React Native skills
								but also learned to manage my projects
								effectively using Git and GitHub. I aim to
								continually improve myself in the industry.
							</p>

							<h2 className='headerText'>🛠️ My Gains</h2>
							<ul className='projectDescription'>
								<li>
									Basic application development skills in
									React Native.
								</li>
								<li>
									Version control and collaboration management
									with Git and GitHub.
								</li>
								<li>
									UI/UX design principles and developing
									user-friendly interfaces.
								</li>
								<li>
									Asynchronous programming and API
									integration.
								</li>
								<li>
									Database management and data storage
									techniques.
								</li>
							</ul>

							<h2 className='headerText'>📈 My Future Goals</h2>
							<ul className='projectDescription'>
								<li>
									Continue developing mobile applications and
									work on more complex projects.
								</li>
								<li>
									Learn more backend technologies to
									strengthen my full-stack skills.
								</li>
								<li>
									Stay updated with innovative technologies
									and closely follow industry developments.
								</li>
							</ul>

							<h2 className='headerText'>
								🔗 Communication and Collaboration
							</h2>
							<ul className='projectDescription'>
								<li>
									During this period, I developed effective
									communication and collaboration skills
									within a team, along with my experiences in
									project management. I thoroughly enjoyed
									being part of a dynamic team like Deka Tech.
								</li>
							</ul>

							<h2 className='headerText'>Information</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>Intern</p>
									<span className='projectCategoryPointer'>
										Team size
									</span>
									<p className='projectDescription'>4</p>
								</div>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Language
									</span>
									<p className='projectDescription'>
										Turkish
									</p>
									<span className='projectCategoryPointer'>
										Country
									</span>
									<p className='projectDescription'>
										Türkiye
									</p>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
								<div className='projectItem'>
									<p className='projectDescription'>
										İhsan Katmer
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInIhsan}
									>
										LinkedIn
									</span>
								</div>
							</div>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Mustafa Burak Yılmaz
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInBurak}
									>
										LinkedIn
									</span>
								</div>
								<div className='projectItem'>
									<p className='projectDescription'>
										Uğurcan Katmer
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInUgurcan}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default Deka;
