import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal';
import Image4 from '../assets/images/egeorman.png';
import { FaDesktop, FaLanguage, FaCoffee } from 'react-icons/fa';
import TurkishFlag from '../assets/images/images.png';
import Image1 from '../assets/images/linkedin-post.png';

function EgeOrman({ onClose }) {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openLinkedInIhsan = () => {
		window.open('https://www.linkedin.com/in/ihsanktmr/', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='Ege Orman Vakfı'
								className='logoImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>
									Ege Orman Vakfı
								</h2>
								<span className='projectCategory'>
									Environment and Nature Monitoring
								</span>
							</div>
						</div>
					</div>

					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<img
									src={TurkishFlag}
									alt='Turkish Flag'
									className='flag-icon'
								/>
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Türkiye</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>Turkish</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaCoffee />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Environment and Nature Monitoring</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaDesktop />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>Web</p>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this project</h2>
						<span className='projectCategory'>
							April 2024 - now
						</span>
						<div>
							<p className='projectDescription'>
								The Ege Orman Vakfı project supports
								environmental and nature conservation efforts,
								collecting and analyzing data in these areas.
								This is a large-scale, comprehensive project
								carried out with the help of significant
								institutions such as the European Union, iED
								(Institute of Entrepreneurship Development), the
								Ministry of Agriculture and Forestry, the
								Ministry of Labor and Social Security, the Olive
								Research Institute Directorate, İzmir Commodity
								Exchange, İzmir Provincial Directorate of
								Agriculture and Forestry, Ege University,
								HBODER, and the Ege Orman Vakfı.
							</p>
							<p className='projectDescription'>
								These organizations have provided extensive
								support, making this project an exemplary
								initiative aimed at protecting the environment
								and enhancing agricultural practices. The
								project involves significant data collection,
								analysis, and reporting aimed at sustainable
								agricultural and environmental solutions,
								leveraging the latest technological
								developments.
							</p>
							<h2 className='headerText'>Preview</h2>

							<div className='imageGrid'>
								<img
									src={Image1}
									alt='Ranking Interface'
									className='appImage'
									onClick={() => openModal(Image1)}
								/>
							</div>
							<h2 className='headerText'>
								Technical Details and Completed Works
							</h2>

							<h3>OAuth2 Authentication and Token Management:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Token Generation:</strong> Using the
									OAuth2 authentication system, secure user
									login was ensured. Access tokens and refresh
									tokens were generated using the
									authorization code.
								</li>
								<li>
									<strong>Token Renewal:</strong> As the token
									expiry approached, new access tokens were
									automatically obtained using the refresh
									token.
								</li>
							</ul>

							<h3>API Requests:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Authorized Requests:</strong> API
									requests were made in an authorized manner
									using access tokens.
								</li>
								<li>
									<strong>Data Fetching:</strong> Data was
									fetched from different API endpoints and
									presented to users within the application.
								</li>
								<li>
									<strong>Testing with Postman:</strong> API
									requests were tested and validated in
									real-time using Postman.
								</li>
							</ul>

							<h3>Graphs and Tables:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Data Visualization:</strong> Fetched
									data was visualized as graphs and tables for
									easy understanding by users.
								</li>
								<li>
									<strong>React Plotly:</strong> The React
									Plotly library was used for creating the
									graphs.
								</li>
								<li>
									<strong>Dynamic Tables:</strong> Fetched
									data was displayed in dynamic tables,
									allowing users to perform various operations
									on the data.
								</li>
							</ul>

							<h3>Creating New Screens:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Graph and Table Screens:</strong>{' '}
									New screens were created for visualizing and
									analyzing the fetched data.
								</li>
								<li>
									<strong>User-Friendly Interface:</strong>{' '}
									User-friendly interfaces were designed to
									allow users to easily examine the data.
								</li>
							</ul>

							<h3>
								Generating OAuth2 Token System from Scratch:
							</h3>
							<ul className='projectDescription'>
								<li>
									<strong>OAuth2 Flow:</strong> The OAuth2
									authorization flow was implemented from
									scratch to ensure secure user login and data
									fetching.
								</li>
								<li>
									<strong>Token Validation:</strong> The
									validity and accuracy of tokens were checked
									with each API request.
								</li>
							</ul>

							<h3>Real-Time Work:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>
										Real-Time Work with Postman:
									</strong>{' '}
									API endpoints were verified and necessary
									adjustments were made using Postman.
								</li>
								<li>
									<strong>Error Management:</strong> Necessary
									precautions were taken for possible errors
									in API requests, and error management was
									performed.
								</li>
							</ul>

							<h2 className='headerText'>Information</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>
										React Developer
									</p>
									<span className='projectCategoryPointer'>
										Team Size
									</span>
									<p className='projectDescription'>2</p>
								</div>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Language
									</span>
									<p className='projectDescription'>
										Turkish
									</p>
									<span className='projectCategoryPointer'>
										Country
									</span>
									<p className='projectDescription'>
										Türkiye
									</p>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
								<div className='projectItem'>
									<p className='projectDescription'>
										İhsan Katmer
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInIhsan}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default EgeOrman;
