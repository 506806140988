import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Works from './pages/Works';
import GlutenMetre from './components/GlutenMetre';

function App() {
	return (
		<Router>
			<div>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/projects' element={<Projects />} />
					<Route path='/contact' element={<Works />} />
					<Route path='/glutenmetre' element={<GlutenMetre />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
