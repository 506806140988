import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import Image6 from '../assets/images/tWeather.png';
import Image5 from '../assets/images/nikeApp.png';
import Image4 from '../assets/images/movieApp.png';
import FeaturedImage from '../assets/images/GlutenBg.png';

function Projects({
	onClose,
	onSeeMore,
	onNikeClone,
	onMovieApp,
	onWeatherApp,
	onUlkePuan,
}) {
	const navigate = useNavigate();

	return (
		<div className='outerContainer'>
			<div className='fixedTop'></div>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<h2 className='headerText'>Featured</h2>
						<div className='projectContainer'>
							<div className='projectInfo'>
								<h2 className='projectTitle'>GlutenMetre</h2>
								<span className='projectCategory'>
									Food & Track
								</span>
								<p className='projectDescription'></p>
								<button
									className='seeMoreButton'
									onClick={onSeeMore}
								>
									<p>See more</p>
								</button>
							</div>
							<div className='projectImageContainer'>
								<img
									src={FeaturedImage}
									alt='GlutenMetre'
									className='projectImage'
								/>
							</div>
						</div>
					</div>
					<div className='main'>
						<h2 className='headerText'>My Projects</h2>
						<div className='projectsGrid'>
							<div className='projectItem' onClick={onWeatherApp}>
								<div className='projectItemInfo'>
									<h3>tWeather</h3>
									<span>Weather Forecast</span>
								</div>
								<img
									src={Image6}
									alt='tWeather'
									className='projectItemImage'
								/>
							</div>
							<div className='projectItem' onClick={onNikeClone}>
								<div className='projectItemInfo'>
									<h3>Nike Shoes</h3>
									<span>Shoppig & Shoes</span>
								</div>
								<img
									src={Image5}
									alt='Nike App'
									className='projectItemImage'
								/>
							</div>
						</div>
						<div className='projectsGrid'>
							<div className='projectItem' onClick={onMovieApp}>
								<div className='projectItemInfo'>
									<h3>tMovie</h3>
									<span>Movies</span>
								</div>
								<img
									src={Image4}
									alt='tMovie'
									className='projectItemImage'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Projects;
