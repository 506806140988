import React, { useState } from 'react';
import '../styles/start.css';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'; // Import icons from react-icons

function About({ onProjects, onWorks }) {
	const handleLinkClick = (url) => {
		window.open(url, '_blank');
	};

	const [isMiddleVisible, setIsMiddleVisible] = useState(false);

	const toggleMiddleVisibility = () => {
		setIsMiddleVisible(!isMiddleVisible);
	};

	return (
		<div className='startContainer'>
			<div className='iconRow'>
				<div className='appContainer' onClick={onProjects}>
					<div className='folderContainer'></div>
					<p className='appName'>Projects</p>
				</div>
				<div
					className='appContainer'
					onClick={() =>
						handleLinkClick(
							'https://drive.google.com/file/d/1i2GPWEC9bGh1hYq0NiKWnTdhGrr68X1L/view?usp=sharing'
						)
					}
				>
					<div className='adobeContainer'></div>
					<p className='appName'>Resume</p>
				</div>
				<div
					className='appContainer'
					onClick={() =>
						handleLinkClick(
							'https://www.linkedin.com/in/talhaonder/'
						)
					}
				>
					<div className='linkedinContainer'></div>
					<p className='appName'>LinkedIn</p>
				</div>
				<div
					className='appContainer'
					onClick={() =>
						handleLinkClick('https://github.com/talhaonder')
					}
				>
					<div className='githubContainer'></div>
					<p className='appName'>Github</p>
				</div>
			</div>

			{isMiddleVisible && (
				<div className='containerMiddle'>
					{/* 					<div className='middleText'>
						<h1 className='middleTextL'>Phone</h1>
						<p className='middleTextR'>+123 456 7890</p>
					</div> */}
					<div className='middleText'>
						<h1 className='middleTextL'>Email</h1>
						<p className='middleTextR'>ltalhaonderl@gmail.com</p>
					</div>
					{/* 					<div className='middleText'>
						<h1 className='middleTextL'>Website</h1>
						<p className='middleTextR'>Coming soon!</p>
					</div> */}
					<div className='middleText'>
						<h1 className='middleTextL'>Experience</h1>
						<p className='middleTextR'>1+ Years</p>
					</div>
					<div className='middleText'>
						<h1 className='skillsLabel'>Skills</h1>
						<div className='middleTextR'>
							<p>UI/UX</p>
							<p>JavaScript</p>
							<p>NodeJS, NextJS</p>
							<p>React, React Native</p>
							<p>HTML, CSS, REST, Git</p>
							<p>Google AdMob, Expo</p>
							<p>MongoDB, Firebase, PostMan</p>
						</div>
					</div>
				</div>
			)}

			<div className='containerProfile'>
				<div className='startProfile'></div>
				<div className='containerText'>
					<p className='nameText'>Talha Onder</p>
					<p className='nameText'>React & React Native Developer</p>
				</div>
				<button
					onClick={toggleMiddleVisibility}
					className='toggleButton'
				>
					{isMiddleVisible ? <FaChevronDown /> : <FaChevronUp />}
				</button>
			</div>
		</div>
	);
}

export default About;
