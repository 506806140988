import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import Image6 from '../assets/images/tWeather.png';
import Image5 from '../assets/images/nikeApp.png';
import Image4 from '../assets/images/movieApp.png';
import FeaturedImage from '../assets/images/GlutenBg.png';

function NotePad({ onWho, onClose }) {
	const navigate = useNavigate();

	return (
		<div className='outerContainer'>
			<div className='fixedTop'></div>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<h2 className='headerText'>Featured blablabalbalal</h2>
					</div>
					<div className='main'>
						<h2 className='headerText'>My Projects</h2>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NotePad;
