import React, { useState } from 'react';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal';
import Image4 from '../assets/images/nikeAppLogo.jpg';
import { FaGlobe, FaLanguage, FaCoffee, FaMobileAlt } from 'react-icons/fa';
import Image1 from '../assets/images/nike1.png';
import Image2 from '../assets/images/nike2.png';
import Image3 from '../assets/images/nike3.png';
import Image5 from '../assets/images/nike4.png';
import Image6 from '../assets/images/nike5.png';

function NikeClone({ onClose }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openGitHubRepo = () => {
		window.open('https://github.com/talhaonder/NikeApp', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='Nike Shoes'
								className='logoImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>Nike Shoes</h2>
								<span className='projectCategory'>
									Shopping & Shoes
								</span>
							</div>
						</div>
					</div>
					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<FaGlobe />
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Global</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>English</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaCoffee />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Shopping & Shoes</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaMobileAlt />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>iOS & Android</p>
								<h4>Not Published</h4>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this project</h2>
						<span className='projectCategory'>
							March 2024 - April 2024
						</span>
						<div>
							<p className='projectDescription'>
								Explore and shop the latest Nike shoes with our
								intuitive and seamless mobile application. Our
								app enhances your shopping experience with its
								user-friendly interface and fast performance.
							</p>

							<h2 className='headerText'>Preview</h2>
							<div className='imageGrid'>
								<img
									src={Image1}
									alt='Nike Shoes 1'
									className='appImage'
									onClick={() => openModal(Image1)}
								/>
								<img
									src={Image2}
									alt='Nike Shoes 2'
									className='appImage'
									onClick={() => openModal(Image2)}
								/>
								<img
									src={Image3}
									alt='Nike Shoes 3'
									className='appImage'
									onClick={() => openModal(Image3)}
								/>
								<img
									src={Image5}
									alt='Nike Shoes 4'
									className='appImage'
									onClick={() => openModal(Image5)}
								/>
								<img
									src={Image6}
									alt='Nike Shoes 5'
									className='appImage'
									onClick={() => openModal(Image6)}
								/>
							</div>

							<h2 className='headerText'>Key Features</h2>
							<ul className='projectDescription'>
								<li>
									<strong>
										Easy Product Search and Filter Options:
									</strong>{' '}
									Effortlessly find the shoes you are looking
									for from our extensive product range.
								</li>
								<li>
									<strong>
										Add Items to Cart and Complete Checkout:
									</strong>{' '}
									Add your favorite products to the shopping
									cart and easily complete your purchases.
								</li>
								<li>
									<strong>
										Review Past Orders and Reorder:
									</strong>{' '}
									View your previous orders and reorder your
									favorite products with ease.
								</li>
							</ul>

							<h2 className='headerText'>Information</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>
										Developer
									</p>
									<span className='projectCategoryPointer'>
										Team Size
									</span>
									<p className='projectDescription'>1</p>
								</div>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Language
									</span>
									<p className='projectDescription'>
										English
									</p>
									<span className='projectCategoryPointer'>
										Country
									</span>
									<p className='projectDescription'>Global</p>
								</div>
								<div className='projectItem'>
									<span
										className='projectCategoryPointer'
										onClick={openGitHubRepo}
									>
										GitHub Repo
									</span>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FullscreenModal
					isOpen={isModalOpen}
					onClose={closeModal}
					imageSrc={modalImage}
				/>
			</div>
		</div>
	);
}

export default NikeClone;
