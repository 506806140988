import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import Image6 from '../assets/images/5k.jpeg';
import Image5 from '../assets/images/deka.jpeg';
import FeaturedImage from '../assets/images/egeorman.png';
import Image1 from '../assets/images/neocrea_cover.jpeg';
import Image2 from '../assets/images/ulkepuan.png';

function Works({
	onClose,
	onDeka,
	onNeoCrea,
	onEgeOrman,
	onKaravan,
	onUlkePuan,
}) {
	const navigate = useNavigate();

	return (
		<div className='outerContainer'>
			<div className='fixedTop'></div>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<h2 className='headerText'>Latest</h2>
						<div className='projectContainer'>
							<div className='projectInfo'>
								<h2 className='projectTitle'>UlkePuan</h2>
								<span className='projectCategory'>
									Football
								</span>
								<p className='projectDescription'></p>
								<button
									className='seeMoreButton'
									onClick={onUlkePuan}
								>
									<p>See more</p>
								</button>
							</div>
							<div className='projectImageContainer'>
								<img
									src={Image2}
									alt='GlutenMetre'
									className='projectImage'
								/>
							</div>
						</div>
					</div>
					<div className='main'>
						<h2 className='headerText'>Freelance</h2>
						<div className='projectsGrid'>
							<div className='projectItem' onClick={onEgeOrman}>
								<div className='projectItemInfo'>
									<h3>Ege Orman Vakfı</h3>
									<span>React Developer</span>
								</div>
								<img
									src={FeaturedImage}
									alt='Ege Orman Vakfı'
									className='projectItemImage'
								/>
							</div>

							<div className='projectItem' onClick={onNeoCrea}>
								<div className='projectItemInfo'>
									<h3>Neocrea Software Solutions</h3>
									<span>React Developer</span>
								</div>
								<img
									src={Image1}
									alt='tWeather'
									className='projectItemImage'
								/>
							</div>
							<div className='projectItem' onClick={onUlkePuan}>
								<div className='projectItemInfo'>
									<h3>UlkePuan</h3>
									<span>React Developer</span>
								</div>
								<img
									src={Image2}
									alt='UlkePuan'
									className='projectItemImage'
								/>
							</div>
						</div>
					</div>
					<div className='main'>
						<h2 className='headerText'>Internship</h2>
						<div className='projectsGrid'>
							<div className='projectItem' onClick={onDeka}>
								<div className='projectItemInfo'>
									<h3>Deka Technology</h3>
									<span>Mobile Developer Intern</span>
								</div>
								<img
									src={Image5}
									alt='Nike App'
									className='projectItemImage'
								/>
							</div>
							<div className='projectItem' onClick={onKaravan}>
								<div className='projectItemInfo'>
									<h3>5K KARAVAN</h3>
									<span>Django & Python Intern</span>
								</div>
								<img
									src={Image6}
									alt='tWeather'
									className='projectItemImage'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Works;
