import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal';
import Image4 from '../assets/images/neocrea_cover.jpeg';
import { FaDesktop, FaLanguage, FaCoffee } from 'react-icons/fa';
import TurkishFlag from '../assets/images/images.png';

function NeoCrea({ onClose }) {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openLinkedInIhsan = () => {
		window.open('https://www.linkedin.com/in/ihsanktmr/', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='NeoCrea'
								className='logoImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>NeoCrea</h2>
								<span className='projectCategory'>
									Software Solutions
								</span>
							</div>
						</div>
					</div>

					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<img
									src={TurkishFlag}
									alt='Turkish Flag'
									className='flag-icon'
								/>
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Türkiye</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>Turkish</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaCoffee />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Freelance</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaDesktop />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>Web</p>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this Job</h2>
						<span className='projectCategory'>
							April 2024 - now
						</span>
						<div>
							<p className='projectDescription'>
								As a freelance developer for NeoCrea, I worked
								on the Ege Orman Vakfı project, focusing on
								environmental and nature conservation efforts.
								The project involved data collection and
								analysis. Below are some of the technical
								details and tasks I performed:
							</p>

							<h2 className='headerText'>
								Technical Details and Completed Works
							</h2>

							<h3>OAuth2 Authentication and Token Management:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Token Generation:</strong> OAuth2
									authentication was used for secure login,
									generating access and refresh tokens via
									authorization code.
								</li>
								<li>
									<strong>Token Renewal:</strong> As tokens
									neared expiry, new access tokens were
									automatically obtained using the refresh
									token.
								</li>
							</ul>

							<h3>API Requests:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Authorized Requests:</strong> API
									requests were authorized using access
									tokens.
								</li>
								<li>
									<strong>Data Fetching:</strong> Data was
									fetched from different API endpoints and
									presented to users.
								</li>
								<li>
									<strong>Testing with Postman:</strong> API
									requests were validated in real-time using
									Postman.
								</li>
							</ul>

							<h3>Graphs and Tables:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Data Visualization:</strong> Fetched
									data was visualized in graphs and tables for
									user clarity.
								</li>
								<li>
									<strong>React Plotly:</strong> React Plotly
									was used to create graphs.
								</li>
								<li>
									<strong>Dynamic Tables:</strong> Data was
									displayed in dynamic tables to allow user
									interaction with the data.
								</li>
							</ul>

							<h3>Creating New Screens:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Graph and Table Screens:</strong>{' '}
									New screens were created to visualize and
									analyze the data.
								</li>
								<li>
									<strong>User-Friendly Interface:</strong>{' '}
									Interfaces were designed to ensure ease of
									use when examining data.
								</li>
							</ul>

							<h3>
								Generating OAuth2 Token System from Scratch:
							</h3>
							<ul className='projectDescription'>
								<li>
									<strong>OAuth2 Flow:</strong> The OAuth2
									authorization flow was implemented from
									scratch for secure login and data access.
								</li>
								<li>
									<strong>Token Validation:</strong> Token
									validity and accuracy were checked with
									every API request.
								</li>
							</ul>

							<h3>Real-Time Work:</h3>
							<ul className='projectDescription'>
								<li>
									<strong>Postman Real-Time Work:</strong> API
									endpoints were tested and adjustments made
									via Postman.
								</li>
								<li>
									<strong>Error Management:</strong> Error
									management was carried out for potential
									issues in API requests.
								</li>
							</ul>

							<h2 className='headerText'>Information</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>
										React Developer
									</p>
									<span className='projectCategoryPointer'>
										Team Size
									</span>
									<p className='projectDescription'>2</p>
								</div>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Language
									</span>
									<p className='projectDescription'>
										Turkish
									</p>
									<span className='projectCategoryPointer'>
										Country
									</span>
									<p className='projectDescription'>
										Türkiye
									</p>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
								<div className='projectItem'>
									<p className='projectDescription'>
										İhsan Katmer
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInIhsan}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default NeoCrea;
