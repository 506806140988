import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal';
import Image4 from '../assets/images/ulkepuan.png';
import { FaLanguage, FaDesktop, FaGlobe, FaFootball } from 'react-icons/fa';
import { IoIosFootball } from 'react-icons/io';

import Image1 from '../assets/images/uecl.png';
import Image2 from '../assets/images/ranking.png';
import Image3 from '../assets/images/eufa.png';

function UlkePuan({ onClose }) {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const visitUlkePuan = () => {
		window.open('https://ulkepuan.net', '_blank');
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='UlkePuan'
								className='logoImage'
							/>

							<div className='projectInfo'>
								<h2 className='projectTitle'>UlkePuan</h2>

								<span className='projectCategory'>
									Football
								</span>
								{/* Add a Visit button here */}
							</div>
							<button
								className='visitButton'
								onClick={visitUlkePuan}
							>
								Visit ulkepuan.net
							</button>
						</div>
					</div>

					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<FaGlobe />
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Global</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>Turkish & English</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<IoIosFootball />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Football</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaDesktop />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>Web</p>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this project</h2>
						<span className='projectCategory'>
							April 2024 - July 2024
						</span>
						<div>
							<p className='projectDescription'>
								UlkePuan is a football prediction game designed
								for fans to predict the ranking of teams in
								UEFA’s three major leagues: Champions League
								(UCL), Europa League (UEL), and Conference
								League (UECL). Players aim to predict which team
								will earn the most points for their country by
								ranking 36 teams in each league.
							</p>
							<h2 className='headerText'>Preview</h2>

							<div className='imageGrid'>
								<img
									src={Image2}
									alt='Ranking Interface'
									className='appImage'
									onClick={() => openModal(Image2)}
								/>
								<img
									src={Image3}
									alt='UEFA'
									className='appImage'
									onClick={() => openModal(Image3)}
								/>
								<img
									src={Image1}
									alt='UECL'
									className='appImage'
									onClick={() => openModal(Image1)}
								/>
							</div>
							<h2 className='headerText'>Technologies Used</h2>
							<ul className='projectDescription'>
								<li>Frontend: React, JavaScript</li>
								<li>Design: CSS, Responsive Layouts</li>
								<li>Functionality: Drag-and-drop UI</li>
								<li>Platforms: Web</li>
							</ul>

							<h2 className='headerText'>Key Features</h2>
							<ul className='projectDescription'>
								<li>
									Multi-league ranking system (UCL, UEL, UECL)
								</li>
								<li>
									Drag-and-drop ranking interface for 36 teams
									per league
								</li>
								<li>
									Independent data management for each league
								</li>
								<li>
									Prize system for the most accurate
									predictions
								</li>
								<li>Weekly leaderboard updates</li>
							</ul>

							<h2 className='headerText'>Role & Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>
										I was the sole developer of UlkePuan,
										handling all aspects of the project from
										the user interface to backend
										integration.
									</p>
									<span className='projectCategoryPointer'>
										Team Size
									</span>
									<p className='projectDescription'>1</p>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default UlkePuan;
