import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal'; // Modal component
import Image4 from '../assets/images/tMovieLogo.png'; // MovieApp logo
import { FaGlobe, FaLanguage, FaCoffee, FaMobileAlt } from 'react-icons/fa'; // Import icons
import Image1 from '../assets/images/movieapp1.png';
import Image2 from '../assets/images/movieapp2.png';
import Image3 from '../assets/images/movieapp3.png';
import Image5 from '../assets/images/movieapp4.png';

function MovieApp({ onClose }) {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openGitHubRepo = () => {
		window.open('https://github.com/talhaonder/MovieApp', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='Movie App Logo'
								className='logoImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>tMovie</h2>
								<span className='projectCategoryPointer'>
									Movies & Series
								</span>
							</div>
						</div>
					</div>

					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<FaGlobe />
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Global</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>English</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaCoffee />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Movies & Series</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaMobileAlt />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>iOS & Android</p>
								<h4>Not Published</h4>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this project</h2>
						<span className='projectCategoryPointer'>
							March 2024 - March 2024
						</span>
						<div>
							<p className='projectDescription'>
								Get comprehensive information on the latest
								movies and series with tMovie. Easily discover
								new films, watch trailers, read reviews, and
								keep track of your favorites.
							</p>

							<h2 className='headerText'>Preview</h2>
							<div className='imageGrid'>
								<img
									src={Image1}
									alt='MovieApp Screenshot 1'
									className='appImage'
									onClick={() => openModal(Image1)}
								/>
								<img
									src={Image2}
									alt='MovieApp Screenshot 2'
									className='appImage'
									onClick={() => openModal(Image2)}
								/>
								<img
									src={Image3}
									alt='MovieApp Screenshot 3'
									className='appImage'
									onClick={() => openModal(Image3)}
								/>
								<img
									src={Image5}
									alt='MovieApp Screenshot 4'
									className='appImage'
									onClick={() => openModal(Image5)}
								/>
							</div>

							<h2 className='headerText'>Key Features</h2>
							<ul className='projectDescription'>
								<li>
									<strong>Discover Latest Movies:</strong>{' '}
									Easily find information on the latest movies
									and series.
								</li>
								<li>
									<strong>Watch Trailers:</strong> Get a
									preview of upcoming films by watching their
									official trailers.
								</li>
								<li>
									<strong>Read Reviews:</strong> Stay informed
									with user and critic reviews to help you
									make your viewing choices.
								</li>
								<li>
									<strong>Track Favorites:</strong> Keep a
									list of movies and series that you want to
									watch in the future.
								</li>
							</ul>

							<h2 className='headerText'>Information</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>
										Developer & Founder
									</p>
									<span className='projectCategoryPointer'>
										Team size
									</span>
									<p className='projectDescription'>1</p>
								</div>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Language
									</span>
									<p className='projectDescription'>
										English
									</p>
									<span className='projectCategoryPointer'>
										Country
									</span>
									<p className='projectDescription'>Global</p>
								</div>
								<div className='projectItem'>
									<span
										className='projectCategoryPointer'
										onClick={openGitHubRepo}
									>
										GitHub Repo
									</span>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default MovieApp;
