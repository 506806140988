import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal'; // Modal component
import Image4 from '../assets/images/tWeatherLogo.png';
import { FaLanguage, FaCoffee, FaMobileAlt, FaGlobe } from 'react-icons/fa'; // Import icons
import Image1 from '../assets/images/HomeScreen.png';
import Image2 from '../assets/images/Location Screen.png';

function WeatherApp({ onClose }) {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openGitHubRepo = () => {
		window.open('https://github.com/talhaonder/weatherApp', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='WeatherApp Logo'
								className='logoImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>tWeather</h2>
								<span className='projectCategory'>
									Weather Forecast
								</span>
							</div>
						</div>
					</div>
					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<FaGlobe />
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Global</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>English</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaCoffee />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Weather Forecast</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaMobileAlt />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>iOS & Android</p>
								<h4>Not Published</h4>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this project</h2>
						<span className='projectCategory'>
							October 2023 - December 2023
						</span>
						<div>
							<p className='projectDescription'>
								Stay updated with the latest weather forecasts
								and trends! tWeather is a comprehensive and
								user-friendly mobile application designed to
								provide real-time weather updates, future
								weather predictions, and detailed weather
								information for locations around the globe.
							</p>
							<h2 className='headerText'>Preview</h2>
							<div className='imageGrid'>
								<img
									src={Image1}
									alt='HomeScreen'
									className='appImage'
									onClick={() => openModal(Image1)}
								/>
								<img
									src={Image2}
									alt='Location Screen'
									className='appImage'
									onClick={() => openModal(Image2)}
								/>
							</div>

							<h2 className='headerText'>Key Features</h2>
							<ul className='projectDescription'>
								<li>Receive real-time weather updates.</li>
								<li>Learn about future weather forecasts.</li>
								<li>
									Automatically view your current location on
									the map.
								</li>
								<li>
									Save and review your past weather searches.
								</li>
							</ul>

							<p className='projectDescription'>
								Ideal for anyone looking to access weather
								information quickly and reliably. The app is
								designed to make it easy for users to access
								weather data and stay informed about upcoming
								weather trends.
							</p>

							<h2 className='headerText'>Information</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>
										Developer & Founder
									</p>
									<span className='projectCategoryPointer'>
										Team Size
									</span>
									<p className='projectDescription'>1</p>
								</div>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Language
									</span>
									<p className='projectDescription'>
										English
									</p>
									<span className='projectCategoryPointer'>
										Country
									</span>
									<p className='projectDescription'>Global</p>
								</div>
								<div className='projectItem'>
									<span
										className='projectCategoryPointer'
										onClick={openGitHubRepo}
									>
										GitHub Repo
									</span>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default WeatherApp;
