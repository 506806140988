import React, { useState, useEffect } from 'react';
import '../styles/myStyles.css';
import Projects from './Projects';
import GlutenMetre from '../components/GlutenMetre';
import NikeClone from '../components/NikeClone';
import EgeOrman from '../components/EgeOrman';
import MovieApp from '../components/MovieApp';
import WeatherApp from '../components/WeatherApp';
import Works from './Works';
import NeoCrea from '../components/NeoCrea';
import Karavan from '../components/Karavan';
import Deka from '../components/Deka';
import About from './About';
import UlkePuan from '../components/UlkePuan';
import WhoAmI from '../components/WhoamI';
import NotePad from './NotePad';
const HomeScreen = () => {
	const [date, setDate] = useState(new Date());
	const [currentScreen, setCurrentScreen] = useState('home');
	const [previousScreen, setPreviousScreen] = useState('home');

	useEffect(() => {
		const intervalId = setInterval(() => {
			setDate(new Date());
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	const formattedDate = date.toLocaleDateString();
	const formattedTime = date.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
	});

	const handleLinkClick = (url) => {
		window.open(url, '_blank');
	};

	const handleMailClick = () => {
		window.location.href =
			'mailto:ltalhaonderl@gmail.com?subject=Contact&body=Hello,';
	};

	/* 	const handleMacOsModeClick = () => {
		alert('Coming soon! This feature is under development.');
	}; */

	const handleCloseOverlay = () => {
		setCurrentScreen(previousScreen);
	};

	const handleScreenChange = (screen) => {
		setPreviousScreen(currentScreen);
		setCurrentScreen(screen);
	};

	const renderContent = () => {
		switch (currentScreen) {
			case 'projects':
				return (
					<Projects
						onClose={() => handleScreenChange('home')}
						onSeeMore={() => handleScreenChange('glutenMetre')}
						onNikeClone={() => handleScreenChange('NikeClone')}
						onEgeOrman={() => handleScreenChange('EgeOrman')}
						onMovieApp={() => handleScreenChange('MovieApp')}
						onWeatherApp={() => handleScreenChange('WeatherApp')}
					/>
				);
			case 'About':
				return (
					<About
						onClose={handleCloseOverlay}
						onSeeMore={() => handleScreenChange('glutenMetre')}
						onNikeClone={() => handleScreenChange('NikeClone')}
						onEgeOrman={() => handleScreenChange('EgeOrman')}
						onMovieApp={() => handleScreenChange('MovieApp')}
						onWeatherApp={() => handleScreenChange('WeatherApp')}
						onProjects={() => handleScreenChange('projects')}
						onWorks={() => handleScreenChange('works')}
					/>
				);
			case 'Note':
				return (
					<NotePad
						onClose={handleCloseOverlay}
						onWho={() => handleScreenChange('WhoAmI')}
					/>
				);
			case 'glutenMetre':
				return <GlutenMetre onClose={handleCloseOverlay} />;
			case 'UlkePuan':
				return <UlkePuan onClose={handleCloseOverlay} />;
			case 'NikeClone':
				return <NikeClone onClose={handleCloseOverlay} />;
			case 'MovieApp':
				return <MovieApp onClose={handleCloseOverlay} />;
			case 'WeatherApp':
				return <WeatherApp onClose={handleCloseOverlay} />;
			case 'WhoAmI':
				return <WhoAmI onClose={handleCloseOverlay} />;
			case 'EgeOrman':
				return <EgeOrman onClose={handleCloseOverlay} />;
			case 'works':
				return (
					<Works
						onClose={() => handleScreenChange('home')}
						onEgeOrman={() => handleScreenChange('EgeOrman')}
						onNeoCrea={() => handleScreenChange('Neocrea')}
						onDeka={() => handleScreenChange('Deka')}
						onKaravan={() => handleScreenChange('Karavan')}
						onUlkePuan={() => handleScreenChange('UlkePuan')}
					/>
				);
			case 'Neocrea':
				return <NeoCrea onClose={handleCloseOverlay} />;
			case 'Deka':
				return <Deka onClose={handleCloseOverlay} />;
			case 'Karavan':
				return <Karavan onClose={handleCloseOverlay} />;
			default:
				return null;
		}
	};

	return (
		<div className='windowsContainer'>
			<div className='imageContainer'></div>
			<div
				className={`mainContent ${
					currentScreen !== 'home' ? 'dimmed' : ''
				}`}
			>
				<div className='appSidebar'>
					<div
						className='appContainer'
						onClick={() =>
							handleLinkClick(
								'https://drive.google.com/file/d/1Hf113PxJbFkbUtKMh2SPgmdSKpqTEvXK/view?usp=sharing'
							)
						}
					>
						<div className='adobeContainer'></div>
						<p className='appName'>Resume</p>
					</div>
					<div
						className='appContainer'
						onClick={() =>
							handleLinkClick(
								'https://www.linkedin.com/in/talhaonder/'
							)
						}
					>
						<div className='linkedinContainer'></div>
						<p className='appName'>LinkedIn</p>
					</div>
					<div
						className='appContainer'
						onClick={() =>
							handleLinkClick('https://github.com/talhaonder')
						}
					>
						<div className='githubContainer'></div>
						<p className='appName'>Github</p>
					</div>
					{/* 					<div
						className='appContainer'
						onClick={handleMacOsModeClick}
					>
						<div className='macosContainer'></div>
						<p className='appName'>macOs mode</p>
					</div> */}
					<div
						className='appContainer'
						onClick={() =>
							handleLinkClick(
								'https://www.buymeacoffee.com/talhaonder'
							)
						}
					>
						<div className='buyCoffeContainer'></div>
						<p className='appName'>Buy Me a Coffee</p>
					</div>
					<div
						className='appContainer'
						onClick={() => handleScreenChange('projects')}
					>
						<div className='folderContainer'></div>
						<p className='appName'>Projects</p>
					</div>
					<div
						className='appContainer'
						onClick={() => handleScreenChange('works')}
					>
						<div className='worksContainer'></div>
						<p className='appName'>Works</p>
					</div>
					<div
						className='appContainer'
						onClick={() => handleScreenChange('WhoAmI')}
					>
						<div className='noteContainer'></div>
						<p className='appName'>Cover Letter</p>
					</div>
				</div>
				<div className='taskbar'>
					<div className='taskbarCenter'>
						<div
							className='startButton'
							onClick={() => handleScreenChange('About')}
						></div>
						<div
							className='taskbarMail'
							onClick={handleMailClick}
						></div>
						<div
							className='taskbarNeoCrea'
							onClick={() => handleScreenChange('Neocrea')}
						></div>
						<div
							className='taskbarGlutenMetre'
							onClick={() => handleScreenChange('glutenMetre')}
						></div>
						<div
							className='taskbarEgeOrmanVakfi'
							onClick={() => handleScreenChange('EgeOrman')}
						></div>

						<div
							className='taskbarDeka'
							onClick={() => handleScreenChange('Deka')}
						></div>
						<div
							className='taskbarUlkePuan'
							onClick={() => handleScreenChange('UlkePuan')}
						></div>
						<div
							className='taskbarKaravan'
							onClick={() => handleScreenChange('Karavan')}
						></div>
					</div>
					<div className='taskbarTime'>
						<span>{formattedTime}</span>
						<span>{formattedDate}</span>
					</div>
				</div>
			</div>
			{currentScreen !== 'home' && (
				<div className='projectsOverlay' onClick={handleCloseOverlay}>
					<div onClick={(e) => e.stopPropagation()}>
						{renderContent()}
					</div>
				</div>
			)}
		</div>
	);
};

export default HomeScreen;
