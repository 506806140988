import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal';
import Image4 from '../assets/images/appstore.png';
import { FaLanguage, FaMobileAlt, FaCoffee } from 'react-icons/fa';
import TurkishFlag from '../assets/images/images.png';
import Image1 from '../assets/images/thumbnailFirst.jpg';
import Image2 from '../assets/images/thumbnailSecond.jpg';
import Image3 from '../assets/images/thumbnailThird.jpg';
import Image5 from '../assets/images/thumbnailFour.jpg';
import Image6 from '../assets/images/thumbnailFive.jpg';
import Image7 from '../assets/images/thumbnailSix.jpg';

function GlutenMetre({ onClose }) {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openLinkedInIhsan = () => {
		window.open('https://www.linkedin.com/in/ihsanktmr/', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='GlutenMetre'
								className='logoImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>GlutenMetre</h2>
								<span className='projectCategory'>
									Food & Track
								</span>
							</div>
						</div>
					</div>
					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<img
									src={TurkishFlag}
									alt='Turkish Flag'
									className='flag-icon'
								/>
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Türkiye</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>English</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaCoffee />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Food & Track</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaMobileAlt />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>iOS & Android</p>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this project</h2>
						<span className='projectCategory'>
							April 2024 - July 2024
						</span>
						<div>
							<p className='projectDescription'>
								Check gluten content quickly and reliably!
								GlutenMetre is an innovative and free mobile
								application designed specifically for
								individuals with celiac disease and gluten
								sensitivity. With GlutenMetre, you can:
							</p>
							<ul className='projectDescription'>
								<li>
									Easily learn the content information of
									packaged foods.
								</li>
								<li>
									Quickly get information about gluten
									content.
								</li>
								<li>Search by categories and product names.</li>
								<li>
									Save products you like or don't want to
									forget for later.
								</li>
								<li>Review your past searches.</li>
								<li>
									Get information about various products on
									the main screen.
								</li>
							</ul>
							<p className='projectDescription'>
								Eliminate the difficulties faced by celiac
								patients and those who want to follow a
								gluten-free diet in finding out whether products
								contain gluten. Considering that one out of
								every 100 people in Türkiye has celiac disease,
								this application will make daily life easier for
								everyone.
							</p>

							<h2 className='headerText'>Preview</h2>
							<div className='imageGrid'>
								<img
									src={Image2}
									alt='GlutenMetre Preview 1'
									className='appImage'
									onClick={() => openModal(Image2)}
								/>
								<img
									src={Image3}
									alt='GlutenMetre Preview 2'
									className='appImage'
									onClick={() => openModal(Image3)}
								/>
								<img
									src={Image1}
									alt='GlutenMetre Preview 3'
									className='appImage'
									onClick={() => openModal(Image1)}
								/>
								<img
									src={Image5}
									alt='GlutenMetre Preview 4'
									className='appImage'
									onClick={() => openModal(Image5)}
								/>
								<img
									src={Image6}
									alt='GlutenMetre Preview 5'
									className='appImage'
									onClick={() => openModal(Image6)}
								/>
								<img
									src={Image7}
									alt='GlutenMetre Preview 6'
									className='appImage'
									onClick={() => openModal(Image7)}
								/>
							</div>

							<h2 className='headerText'>Technologies Used</h2>
							<ul className='projectDescription'>
								<li>Frontend: React, JavaScript</li>
								<li>Design: CSS, Responsive Layouts</li>
								<li>Functionality: Food content tracking</li>
								<li>Platforms: iOS & Android</li>
							</ul>

							<h2 className='headerText'>Key Features</h2>
							<ul className='projectDescription'>
								<li>
									Easily learn the content information of
									packaged foods
								</li>
								<li>
									Quickly get information about gluten content
								</li>
								<li>Search by categories and product names</li>
								<li>
									Save products you like or don't want to
									forget for later
								</li>
								<li>Review your past searches</li>
								<li>
									Get information about various products on
									the main screen
								</li>
							</ul>

							<h2 className='headerText'>Information</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>
										Developer & Founder
									</p>
									<span className='projectCategoryPointer'>
										Team Size
									</span>
									<p className='projectDescription'>2</p>
								</div>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Language
									</span>
									<p className='projectDescription'>
										English
									</p>
									<span className='projectCategoryPointer'>
										Country
									</span>
									<p className='projectDescription'>
										Türkiye
									</p>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
								<div className='projectItem'>
									<p className='projectDescription'>
										İhsan Katmer
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInIhsan}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default GlutenMetre;
